
/*update this if you add more carousel slides. they all need to be display none except the first one*/
#slide2, #slide3 {
    display: none;
}

#dot1 {
    transform: scale(1.25);
}

.carousel-container {
    display: flex;
    flex-direction: row;
}

.dots-container {
    position: absolute;
    width: 100vw;
    bottom: 5vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    opacity: .5;
}

.dot {
    width: 15px;
    height: 15px;
    border-radius: 50px;
    border: 2px solid white;
    background-color: black;
    margin: 5px;
    transition: 100ms ease-in-out;
}

.dot:hover {
    cursor: pointer;
}

img {
    width: 100vw;
    min-width: 100vw;
    height: 100vh;
    object-fit: cover;
}
