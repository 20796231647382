.stickerOuterGradientShape {
    position: absolute;
    bottom: 5vh;
    right: 5vh;
    width: 128px;
    height: 128px;
    border-radius: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: linear-gradient(45deg, red, blue); */
    background-image: linear-gradient( 45deg,  rgba(31,212,248,1) 11%, rgba(218,15,183,1) 74.9% );
}

.stickerOuterGradientShape:hover {
    background-image: linear-gradient( 45deg,  rgba(218,15,183,1) 11%, rgba(31,212,248,1) 74.9% );
    cursor: pointer;
}

.stickerShape {
    width: 120px;
    height: 120px;
    border-radius: 200px;
    animation: 10s infinite linear rotate;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-360deg);
    }
}


@media (max-width: 500px) {
    .outerGradientShape {
        bottom: 2.5vh;
        right: 2.5vh;
    }
  }