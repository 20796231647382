#panelCloseIcon {
    position: absolute;
    top: 5vh; 
    right: 5vh; 
    transition: 200ms ease-in-out;
}

#panelCloseIcon:hover {
    transform: rotate(-90deg);
    cursor: pointer;
}

.contactPanelGradientBackground {
    background-image: linear-gradient( 45deg,  rgba(31,212,248,1) 11%, rgba(218,15,183,1) 74.9% );
    z-index: 10;
    position: absolute;
    top: 5vh;
    left: 5vh;
    width: calc(100vw - 10vh);
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: .98;
    border-radius: 2px;
}

.contactPanel {
    z-index: 11;
    width: calc(100vw - 10vh - 8px);
    height: calc(90vh - 8px);
    background-color: #0a1128;
    color: #ffffff;
    padding: 5vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    gap: 5vh;
    opacity: .98;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #0a1128;
    padding-right: 5vh;
    border-radius: 2px;
}

.contactPanel::-webkit-scrollbar {
    width: 2.5px;
  }
  
.contactPanel::-webkit-scrollbar-thumb {
    background-color: grey; /* Set thumb color for Chrome and Safari */
}

.contactPanel::-webkit-scrollbar-track {
    background-color: #0a1128; /* Set track color for Chrome and Safari */
}

.column {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.profilePictureContainer {
    margin: 20px 0;
    background-color: #0a1128;
    width: 200px;
    height: 200px;
    border-radius: 200px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.profilePicture {
    object-fit: contain;
    width: 200px;
    height: 200px;
}

.socialIconsContainer {
    margin-top: 20px;
}

.aboutText {
    width: 70%;
}

.column2 {
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #0a1128;
    padding-right: 5vh;
}

.column2::-webkit-scrollbar {
    width: 2.5px;
  }
  
.column2::-webkit-scrollbar-thumb {
    background-color: grey; /* Set thumb color for Chrome and Safari */
}

.column2::-webkit-scrollbar-track {
    background-color: #0a1128; /* Set track color for Chrome and Safari */
}

.contactForm {
    display: flex;
    flex-direction: column;
    gap: 2.5vh;
    margin-top: 20px;
}

input, textarea {
    background-color: transparent;
    border: none;
    border-bottom: 2px solid white;
    border-radius: 2px;
    color: white;
}

textarea {
    min-height: 50px;
}

.contactPanel p {
    line-height: 1.3rem;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.inputContainer label {
    margin-bottom: 10px;
}

.formSubmitButton {
    all:unset;
}

.sideBySide {
    display: flex;
    flex-direction: row;
    gap: 2.5vh;
    width: 100%;
    align-items: center;
}

@media (max-width: 900px) {
    .contactPanel {
        flex-direction: column; 
    }

    .column {
        width: 90%;
        height: auto;
    }

    .aboutText {
        width: 100%;
    }

    .column2 {
        all: unset;
    }

  }

  @media (max-width: 500px) {
    .sideBySide {
        flex-direction: column;
    }
  }