.projectDrawerGradientEdge {
    position: absolute;
    right: 0;
    top: 0;
    height: 100vh;
    width: 400px;
    display: flex;
    justify-content: flex-end;
    background-image: linear-gradient( 45deg,  rgba(31,212,248,1) 11%, rgba(218,15,183,1) 74.9% );
    opacity: .98;
    z-index: 10;
}

.drawerPanel {
    width: 396px;
    background-color: #0a1128;
    padding: 5vh;
    box-sizing: border-box;
    color: white;
    opacity: .98;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #0a1128;
    padding-right: 5vh;
}

.drawerPanel::-webkit-scrollbar {
    width: 2.5px;
  }
  
.drawerPanel::-webkit-scrollbar-thumb {
background-color: grey; /* Set thumb color for Chrome and Safari */
}

.drawerPanel::-webkit-scrollbar-track {
background-color: #0a1128; /* Set track color for Chrome and Safari */
}

h2 {
    margin: 0;
    font-weight: 900;
}

#drawerCloseIcon {
    position: absolute;
    top: 5vh; 
    right: 5vh; 
    transition: 200ms ease-in-out;
}

#drawerCloseIcon:hover {
    transform: rotate(-90deg);
    cursor: pointer;
}